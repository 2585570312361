import {
  ClassTeacherDetailsType,
  RelationshipManagerType,
  SchoolDetailsType,
} from '@lib/types/common'
import { createContext, useContext } from 'react'

export type UserProps = {
  loggedIn: boolean
  id: number
  isPaidUser: boolean
  isCompetitionUser: boolean
  username: string
  name: string
  email: string
  phone: string
  voucher?: string
  dob?: string
  gender?: string
  avatar?: string
  hasLaptop?: boolean
  student?: {
    id: number
    grade: string
    parentName?: string
    stats?: {
      rank: number
      points: number
      pendingAssignmentCount: number
      pendingQuizCount: number
    }
    eligibleCompetitionsCount: number
    relationshipManager?: RelationshipManagerType
    hasCompletedCourseClass?: boolean
    classTeacherDetails?: ClassTeacherDetailsType
    whatsappGroupUrl?: string
  }
  teacher?: {
    id: number
    tid: number
    calendarId: number
    educationalQualification: string
    eligibleGrades: {
      grade: string
    }[]
    experience: string
    isVerified: boolean
    hasCompletedClass: boolean
  }
  school: SchoolDetailsType
  leadSource?: {
    id: number
    utmMedium: string
    utmSource: string
    utmCampaign: string
    utmTerm: string
    utmContent: string
  }
  signupReferer?: string
  signupSource?: string
  preferences?: {
    id: number
    whatsappEnabled: boolean
    canTakeInstantTrialClass: boolean
    classTypePreferences: string[]
  }
  timezone?: string
  isInstantClass?: boolean
  country?: string
  city?: string
  availableLanguagesForCountry?: string[]
  totalCredits: number
  totalCodingCredits: number
  totalMathCredits: number
  displayCredits: boolean
  hasPassword: boolean
  isEligibleForRewind: boolean
}
export const SessionContext = createContext<UserProps>({} as UserProps)
export const useSessionContext = () => useContext(SessionContext)
