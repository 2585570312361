import styled from '@emotion/styled'
import { useSessionContext } from '@contexts/session'
import {
  CODINGAL_REWIND_EXPIRY_DATE,
  CODINGAL_REWIND_YEAR,
} from '@common/constants'
import Sparkles from 'react-sparkle'

const HelloBarContainerDiv = styled.a({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '8px',
  fontSize: '18px',
  fontWeight: 700,
  background: '#262424',
  h4: {
    span: {
      color: 'transparent',
      backgroundImage: 'linear-gradient(to right, #9f7aea, #ed64a6)',
      backgroundClip: 'text',
    },
  },
})

export default function CodingalRewindHellobar() {
  const { isEligibleForRewind, loggedIn, username } = useSessionContext()

  if (!loggedIn || !isEligibleForRewind) return null

  const currentDate = new Date()
  if (currentDate > CODINGAL_REWIND_EXPIRY_DATE) return null

  return (
    <HelloBarContainerDiv
      href={`/rewind/${username}`}
      target="_blank"
      rel="noreferrer"
    >
      <h4>
        ✨ ⏪{' '}
        <span>
          Your Codingal Rewind {CODINGAL_REWIND_YEAR} is here! View now
        </span>
      </h4>
      <Sparkles color="yellow" overflowPx={8} />
    </HelloBarContainerDiv>
  )
}
