import PasswordResetModal from '@components/auth/password-reset-modal'
import { useSessionContext } from '@contexts/session'
import { useState } from 'react'

const PasswordResetHelloBar = () => {
  const { loggedIn, hasPassword, isEligibleForRewind } = useSessionContext()
  const [isModalOpen, setIsModalOpen] = useState(false)

  // XXX: Temp disable password reset hellobar.
  if (!loggedIn || hasPassword || isEligibleForRewind) return null

  return (
    <div className="font-600 leading-tight bg-orange text-white py-3 text-center">
      <PasswordResetModal
        isOpen={isModalOpen}
        setIsPassModalOpen={setIsModalOpen}
        hasPassword={false}
      />
      <p>
        It’s recommended to use password to login.{' '}
        <strong
          className="underline cursor-pointer"
          onClick={() => setIsModalOpen(true)}
        >
          Set your password now
        </strong>
      </p>
    </div>
  )
}

export default PasswordResetHelloBar
