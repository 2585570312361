export const setToLocalStorage = (
  key: string,
  value: string,
  onError?: () => void
) => {
  try {
    window.localStorage.setItem(key, value)
  } catch (error) {
    console.error(error)
    if (onError) {
      onError()
    }
  }
}

export const getFromLocalStorage = (key: string) => {
  let value = ''
  try {
    value = window.localStorage.getItem(key)
  } catch (error) {
    console.error(error)
  }

  return value
}

export const removeFromLocalStorage = (key: string) => {
  try {
    window.localStorage.removeItem(key)
  } catch (error) {
    console.error(error)
  }
}
