import { hasRoute } from '@common/utils'
import { useSessionContext } from '@contexts/session'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

// Paths that should not show the hello bar
const RESTRICTED_PATHS = [
  '/batches/**',
  '/lp/**',
  '/trial-class/**',
  '/competitions/**',
  '/book-trial-class',
  '/payment/**',
  '/rewind/**',
]

const TRIAL_CLASS_STUDENT_PATH = [
  '/trial-class/incomplete',
  '/trial-class/complete',
  '/trial-class/join',
  '/competitions/*',
]

const HelloBar = () => {
  const { loggedIn } = useSessionContext()

  const { pathname } = useRouter()

  if (
    hasRoute(pathname, RESTRICTED_PATHS) &&
    !hasRoute(pathname, TRIAL_CLASS_STUDENT_PATH)
  )
    return null

  if (loggedIn) return null

  return (
    <>
      <div
        className="md:top-0 z-10 p-2 md:px-3 lg:text-xl text-center text-blue-300 font-600 lg:font-700"
        style={{
          background:
            'linear-gradient(166deg, rgb(255, 108, 108), rgb(220, 209, 209), rgb(4, 106, 56))',
        }}
      >
        <Link
          href="/register/?utm_source=republic-day-hellobar&utm_medium=website-hellobar"
          prefetch={false}
        >
          <a target="_blank" rel="noreferrer" className="hover:underline">
            REPUBLIC DAY SALE! - 50% OFF ON ALL AI & CODING COURSES
          </a>
        </Link>
      </div>
    </>
  )
}

export default React.memo(HelloBar)
