import { FastlyImage } from '@components/common/image'
import { useEffect, useState } from 'react'
import { X } from 'react-feather'
import ReactModal from 'react-modal'
type PopupProps = {
  displayPopup: boolean
  imageUrl: string
  targetUrl: string
  imageWidth: number
  imageHeight: number
}
// Temporary popup Component can be used to show popup on any page.
// Todo: Make it as a generic component, so that it can be configured from the _app.tsx
// Add allowed routerpath, country, name of the popup,.. as Props to show popup based on the configuration
const Popup = ({
  displayPopup,
  imageUrl,
  targetUrl,
  imageWidth,
  imageHeight,
}: PopupProps) => {
  const [showPopup, setShowPopup] = useState(false)

  // delay 5 seconds to show the popup
  useEffect(() => {
    setTimeout(() => {
      setShowPopup(displayPopup)
    }, 5000)
  }, [displayPopup])

  useEffect(() => {
    if (sessionStorage.getItem('offerPopup')) {
      setShowPopup(false)
    }
    if (showPopup) {
      sessionStorage.setItem('offerPopup', 'true')
    }
  }, [showPopup])

  if (showPopup)
    return (
      <ReactModal
        className="fixed inset-0 inset-x-0 flex items-center justify-center px-4 pb-4 outline-none"
        overlayClassName="z-50 fixed inset-0 bg-smoke"
        isOpen={showPopup}
        shouldCloseOnEsc={true}
        onRequestClose={() => setShowPopup(false)}
      >
        <div
          className="fixed inset-0 transition-opacity"
          onClick={() => setShowPopup(false)}
        >
          <div className="absolute inset-0 opacity-7 bg-grey bg-opacity-50" />
        </div>
        <div
          className="relative z-30 content-center"
          style={{ height: '80%' }}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-end">
            <button
              className="focus:outline-none bg-white hover:bg-grey-600 bg-opacity-25"
              onClick={() => setShowPopup(false)}
            >
              <X className="stroke-2 text-orange" />
            </button>
          </div>
          <a
            href={targetUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => setShowPopup(false)}
          >
            <FastlyImage
              src={`${imageUrl}.png`}
              webpSrc={`${imageUrl}.webp`}
              alt="Codingal blackfriday popup"
              className="w-auto md:h-full"
              width={imageWidth}
              height={imageHeight}
            />
          </a>
        </div>
      </ReactModal>
    )
  return null
}
export default Popup
